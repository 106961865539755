import React, { useEffect } from 'react'
const location = () => (typeof window !== `undefined`) ? window.location : { search: '', host: '' }


export function flattenObject(obj, prefix = '') {
  if (!obj) return {}
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});
}

export function language() {
  const params = new URLSearchParams(location().search)
  return params.get('language') || 'en'
}

export function origin() {
  const params = new URLSearchParams(location().search)
  return params.get('origin')
}

export function originUrl() {
  const params = new URLSearchParams(location().search)
  return params.get('originUrl')
}

export function useKeyboardEvent(key, callback) {
  useEffect(() => {
    const handler = event => {
      if (event.key === key) callback()
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  })
}

export function DisableBodyScroll() {
  return <style dangerouslySetInnerHTML={{ __html: 'body { overflow: hidden }' }} />
}

export function mod(a, n) {
  return ((a % n) + n) % n
}

export function getImageBySize(photo, size) {
  if (!photo) return
  if (!photo.formats && photo.url) return photo.url
  if (!photo.formats) return photo
  return photo.formats[size]?.url || photo.url
}

export function computeInnerSize(parent) {
  const left = parent.children[0].getBoundingClientRect().left
  const right = parent.children[parent.children.length - 1].getBoundingClientRect().right
  return right - left
}
export function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}
