import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, href, children, style, className, variant = 'default', Icon, onClickDisabled, disabled = false, soon = false }) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: 'stroke-2 mr-2 -ml-1',
    primary: 'mr-2 -ml-1',
    yellow: 'stroke-2 text-lg mr-2 -ml-1',
    secondary: 'stroke-2 mr-2 -ml-1',
    icon: 'stroke-1 w-full h-full',
    'icon-white': 'stroke-1 w-full h-full'
  }
  const styles = {
    default: `text-base font-normal py-2 px-6 text-white bg-orange hover:bg-orange-dark`,
    yellow: `text-base font-semibold py-2 px-6 bg-yellow uppercase hover:bg-yellow`,
    transparent: `text-base uppercase py-2 px-6 text-white bg-transparent border-2 border-white font-bold transition duration-200 transform hover:scale-110`,
    primary: `text-lg font-normal py-3 px-8 text-white bg-orange hover:bg-orange-dark`,
    secondary: `text-sm font-normal py-2 px-5 text-white bg-blue hover:bg-blue-dark`,
    small: `text-sm font-normal py-1 px-4 text-white bg-blue hover:bg-blue-dark`,
    'secondary-white': `text-sm font-normal py-2 px-5 text-gray-400 bg-white border border-gray-200 hover:border-gray-300 hover:text-gray-500`,
    white: `text-base font-normal py-2 px-6 text-orange bg-white hover:text-orange-dark`,
    icon: `h-6 w-6 p-2 relative box-content text-black bg-yellow rounded-full`,
    'icon-white': `h-6 w-6 font-light p-2 relative box-content text-orange bg-white rounded-full hover:text-orange-dark`,
  }
  return (
    <a href={href} target={href && href.match('http') ? '_blank' : ''}>
      <button
        onClick={async () => {
          if (disabled && onClickDisabled) return onClickDisabled()
          if (disabled) return
          setLoading(true)
          try {
            await onClick()
          } catch (error) {
            console.error(error)
            setLoading(false)
          }
          setLoading(false)
        }}
        disabled={loading}
        style={style}
        className={`relative overflow-hidden flex justify-center items-center align-middle focus:outline-none select-none flex-shrink-0 transition-all duration-100 rounded shadow hover:shadow-md ${styles[variant]} ${className} ${disabled && 'bg-gray-200 hover:bg-gray-200'}`}>
        {Icon && <Icon className={iconStyles[variant]} />}
        {children}
        {loading &&
          <div className={`absolute w-full h-full p-1 bg-gray-200`}>
            <FiLoader className="stroke-1 rotating box-border w-full h-full" />
          </div>
        }
        {soon &&
          <div className="opacity-0 hover:opacity-100 flex absolute w-full h-full bg-white justify-center items-center">Bientôt disponible</div>
        }
      </button>
    </a>
  )
}
