import React from 'react'
import I18n from './I18n'

export default function FormElement({ title, children, className, error, noLabel }) {
  const messages = {
    'pattern': <I18n>form.wrongFormat</I18n>,
    'required': <I18n>form.required</I18n>
  }
  if (noLabel)
    return (
      <div className={`relative block px-2 sm:px-3 pt-2 pb-1 sm:pb-6 w-full sm:w-1/2 text-gray-700 ${className} ${error && 'text-red-800'}`}>
        <div className="flex justify-between sm:mb-1 font-light pr-2 text-base">
          {title}
        </div>
        {children}
        {error &&
          <div className="absolute top-0 right-0 mt-4 mr-2 sm:mb-1 sm:mr-0 sm:ml-3 sm:bottom-0 sm:top-auto sm:left-0 leading-none font-light text-xs text-red opacity-50">
            {error.type ? messages[error.type] : `${error}`}
          </div>
        }
      </div>
    )
  return (
    <label className={`relative block px-2 sm:px-3 pt-2 pb-1 sm:pb-6 w-full sm:w-1/2 text-gray-700 ${className} ${error && 'text-red-800'}`}>
      <div className="flex justify-between sm:mb-1 font-light pr-2 text-base">
        {title}
      </div>
      {children}
      {error &&
        <div className="absolute top-0 right-0 mt-4 mr-2 sm:mb-1 sm:mr-0 sm:ml-3 sm:bottom-0 sm:top-auto sm:left-0 leading-none font-light text-xs text-red opacity-50">
          {error.type ? messages[error.type] : `${error}`}
        </div>
      }
    </label>
  )
}
